<template>
  <div class="voucher-detail mt10">
    <a-spin :spinning="loading">
      <div class="content-overflow">
        <section class="left-div fixContent" :style="{ height: scrollHeight }">
          <a-form-model ref="ruleForm" :model="formData">
            <ModalBox
              id="BaseInfo"
              :title="$t('记账凭证')"
              :showCopy="isDetail"
              @copyOrder="copyOrder"
            >
              <template v-slot:header>
                <div class="btns flex-ct" style="margin-left: auto">
                  <OperateBtn
                    v-if="formData.status === 'WAIT_CHECK'"
                    api="checkVoucher"
                    :text="$t('审核')"
                    :params="{
                      idList: [id],
                    }"
                    @success="init"
                  />
                </div>
              </template>
              <a-row :gutter="16">
                <a-col :span="6">
                  <a-form-model-item :label="$t(`凭证单据号`)">
                    <a-input disabled v-model="formData.voucherSn"></a-input>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`账簿`)" required>
                    <SelectAccountSet
                      :code="formData.accountSetUnionCode"
                      :disabled="isDetail"
                      @change="changeAccountSet"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`结算组织`)" required>
                    <a-input :disabled="true" :value="formData.businessUnitName" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`业务日期`)" required>
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.bizTime"
                      @choose="chooseBizDate"
                      :disabled="isDisabled"
                      :placehold="$t(`业务日期`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`记账日期`)" required>
                    <DatePicker
                      :needhms="false"
                      style="background-color: #ffffff; width: 100%"
                      :init-date-time="formData.accountingDate"
                      @choose="chooseAccountingDate"
                      :disabled="true"
                      :placehold="$t(`记账日期`)"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`期间`)" required>
                    <CommonQuerySelect
                      api="queryAccountingCalendarDetailPage"
                      valueName="accounting_period_code"
                      labelName="accounting_period_name"
                      :code.sync="formData.accountingPeriod"
                      :placeholder="$t(`期间`)"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`凭证字`)" required>
                    <CommonSelect
                      :list="journal_voucher_type"
                      :code.sync="formData.voucherType"
                      :placeholder="$t('凭证字')"
                      :disabled="isDisabled"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item :label="$t(`备注`)">
                    <a-input v-model="formData.remark" :disabled="isDisabled" />
                  </a-form-model-item>
                </a-col>
                <template v-if="isDetail">
                  <a-col :span="6">
                    <a-form-model-item :label="$t(`来源单据`)">
                      <a-input
                        :value="documentTypeMapping[formData.sourceDocType]"
                        :disabled="true"
                      />
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="6">
                    <a-form-model-item :label="$t(`状态`)">
                      <a-input
                        disabled
                        v-model="journalVoucherStatusMapping[formData.status]"
                      ></a-input>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="24">
                    <a-form-model-item :label="$t(`来源单据单号`)">
                      <template v-if="formData.sourceDocSn?.length > 0">
                        <span
                          class="text-link mr10"
                          v-for="(item, index) in formData.sourceDocSn.split(',')"
                          :key="item"
                          @click="viewSourceDetail(formData, index)"
                        >
                          {{ item }}
                        </span>
                      </template>
                    </a-form-model-item>
                  </a-col>
                </template>
              </a-row>
            </ModalBox>
          </a-form-model>
          <ModalBox id="ProductInfo" :title="$t('明细')">
            <template v-slot:header>
              <div class="option-btns pr20" v-if="!isDisabled">
                <span class="text-link fz16" @click="addLineHandle">
                  {{ $t('增行') }}
                </span>
                <span class="text-link fz16 ml20" @click="deleteLineHandle">
                  {{ $t('删行') }}
                </span>
              </div>
            </template>
            <CommonTable
              ref="CommonTableRef"
              :showSelect="!isDetail"
              :selectedRowKeys.sync="selectedRowKeys"
              :columns="columns"
              :dataSource="formData.detailList"
              :showSetting="false"
              :showPagination="false"
              :scroll="{ x: 1500 }"
            >
              <template v-slot:summary="{ record }">
                <span v-if="isDisabled">{{ record.summary }}</span>
                <a-input v-else v-model="record.summary" :disabled="isDisabled" />
              </template>
              <template v-slot:accountCode="{ record }">
                <span v-if="isDisabled" class="text-link" @click="goToAccountJournalList(record)">
                  {{ record.accountName }}
                </span>
                <a-input
                  readOnly
                  :value="record.accountName"
                  @click="showAccountModal(index)"
                  :placeholder="$t('科目名称')"
                />
              </template>
              <template v-slot:dimensionList="{ record }">
                <template v-if="isDisabled">
                  <div
                    v-for="(item, index) in record.dimensionList"
                    :key="index"
                    class="text-link"
                    @click="goToDimensionList(item)"
                  >
                    {{ item.accountingDimensionName }}: {{ item.accountingDimensionValueName }}
                  </div>
                </template>
                <a-input
                  v-else-if="record.dimensionList?.length > 0"
                  readOnly
                  class="ant-input ellipsis"
                  :disabled="isDisabled"
                  :value="record.dimensionListName"
                  @click="showDimensionModal(record)"
                />
              </template>
              <template v-slot:currency="{ record }">
                <span v-if="isDisabled">{{ currencyListMapping[record.currency] }}</span>
                <SelectCurrency v-else :code.sync="record.currency" :disabled="isDisabled" />
              </template>
              <template v-slot:debitAmount="{ record }">
                <PriceInput
                  :showZero="record.type === 'summary'"
                  :value.sync="record.debitAmount"
                  :disabled="isDisabled"
                  :showTxt="record.type === 'summary' || isDisabled"
                  :currency="record.currency"
                  @blur="
                    (val) => {
                      blurHandle(val, record, 'creditAmount')
                    }
                  "
                />
              </template>
              <template v-slot:creditAmount="{ record }">
                <PriceInput
                  :showZero="record.type === 'summary'"
                  :value.sync="record.creditAmount"
                  :disabled="isDisabled"
                  :showTxt="record.type === 'summary' || isDisabled"
                  :currency="record.currency"
                  @blur="
                    (val) => {
                      blurHandle(val, record, 'debitAmount')
                    }
                  "
                />
              </template>
            </CommonTable>
          </ModalBox>
        </section>
      </div>
      <BottomBtns
        :canEdit="false"
        :isEdit.sync="isEdit"
        :isDetail="isDetail"
        :draftLoading="draftLoading"
        :saveLoading="saveLoading"
        :status="formData.status"
        @submit="onSubmit"
        @cancel="getDetailInfo"
        @back="backForm"
      />
    </a-spin>
    <SelectDimensionModal
      :visible.sync="dimensionVisible"
      :businessUnitCode="formData.businessUnitCode"
      :list="dimensionData.dimensionList"
      @ok="handleDimensionOk"
    />
    <SelectAccountModal ref="SelectAccountModalRef" @ok="selectAccountHandle" />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import moment from 'moment'
import http from '@/service/axios'
import api from '@/service/api'
import DatePicker from '@component/datePicker'
import BottomBtns from '@component/bottomBtns'
import SelectCurrency from '@component/selectCurrency'
import SelectAccountSet from '@component/selectAccountSet'
import SelectDimensionModal from '@component/selectDimensionModal'
import SelectAccountModal from '@component/selectAccountModal'
import { convertKeysToCamelCase, goBack, jumpSoucePage } from '@/common'
import deepClone from '@/common/deepClone'
import debounce from '@/common/debounce'
import { add } from '@/common/number'
export default {
  name: 'VoucherDetail',
  components: {
    DatePicker,
    BottomBtns,
    SelectCurrency,
    SelectAccountSet,
    SelectDimensionModal,
    SelectAccountModal,
  },
  data() {
    return {
      id: undefined,
      isCopy: false,
      isDetail: false, //是否是详情页
      isEdit: false, //是否编辑状态
      formData: {
        accountSetUnionCode: undefined,
        functionalCurrency: undefined,
        detailList: [],
        status: 'DRAFT',
      },
      loading: false,
      draftLoading: false,
      saveLoading: false,
      selectedRowKeys: [],
      dimensionData: {
        dimensionList: [],
      },
      dimensionVisible: false,
      modalIndex: 0,
    }
  },
  activated() {
    this.init()
  },
  beforeRouteUpdate(to, from, next) {
    Object.assign(this.$data, this.$options.data())
    this.$nextTick(() => {
      this.init()
    })
    next()
  },
  computed: {
    ...mapState(['journal_voucher_type']),
    ...mapGetters(['journalVoucherStatusMapping', 'documentTypeMapping', 'currencyListMapping']),
    isDisabled() {
      return this.isDetail && !this.isEdit
    },
    scrollHeight() {
      return window.innerHeight - 200 + 'px'
    },
    columns() {
      return [
        {
          title: this.$t('摘要'),
          dataIndex: 'summary',
          scopedSlots: {
            customRender: 'summary',
          },
          width: 350,
        },
        {
          title: this.$t('科目'),
          dataIndex: 'accountCode',
          scopedSlots: {
            customRender: 'accountCode',
          },
          width: 200,
        },
        {
          title: this.$t('核算维度'),
          dataIndex: 'dimensionList',
          scopedSlots: {
            customRender: 'dimensionList',
          },
          width: 250,
        },
        {
          title: this.$t('币别'),
          dataIndex: 'currncy',
          scopedSlots: {
            customRender: 'currency',
          },
          width: 100,
        },
        {
          title: this.$t('借方金额'),
          dataIndex: 'debitAmount',
          scopedSlots: {
            customRender: 'debitAmount',
          },
          width: 200,
        },
        {
          title: this.$t('贷方金额'),
          dataIndex: 'creditAmount',
          scopedSlots: {
            customRender: 'creditAmount',
          },
          width: 200,
        },
      ]
    },
  },
  methods: {
    init() {
      this.isEdit = false
      if (!this.$route.query.id) {
        Object.assign(this.$data, this.$options.data())
        this.formData.bizTime = moment().format('YYYY-MM-DD')
        this.formData.accountingDate = moment().format('YYYY-MM-DD')
        this.formData.accountingPeriod = moment().format('YYYY-MM')
      } else {
        this.id = this.$route.query.id
        this.isDetail = true
      }
      this.getDetailInfo()
    },
    changeBU(value) {
      this.formData.businessUnitCode = value.business_unit_code
      this.formData.businessUnitName = value.business_unit_name
      this.formData.functionalCurrency = value.currency
    },
    changeAccountSet(value) {
      let arr = value.unionCode?.split('-')
      this.formData.accountSetUnionCode = value.unionCode
      this.changeBU(value || {})
      if (arr.length > 1) {
        this.formData.accountSetCode = arr[0]
      } else {
        this.formData.accountSetCode = undefined
      }
    },
    getDetailInfo() {
      this.isCopy = false
      let voucherFormdata = localStorage.getItem('voucherFormdata')
      if (this.$route.query.id) {
        this.loading = true
        http({
          url: api.getVoucherDetail,
          data: {
            id: this.id,
          },
          success: (res) => {
            let result = convertKeysToCamelCase(deepClone(res.result))
            result.detailList.forEach((item) => {
              this.getShowName(item)
              if (item.direction === 'CREDIT') {
                item.creditAmount = item.amount
              } else {
                item.debitAmount = item.amount
              }
            })
            if (result.status === 'DRAFT') {
              this.isEdit = true
            }
            result.accountSetUnionCode = result.accountSetCode + '-' + result.businessUnitCode
            this.formData = { ...this.formData, ...result }
            this.loading = false
            this.$nextTick(() => {
              this.calTotalLine()
            })
          },
          fail: (err) => {
            this.loading = false
            this.$message.error(err.msg || err)
          },
        })
      } else if (voucherFormdata) {
        this.isCopy = true
        let data = JSON.parse(voucherFormdata)
        this.formData = data
        this.formData.voucherSn = undefined
        this.formData.id = undefined
        this.formData.status = 'DRAFT'
        this.formData.accountingDate = moment().format('YYYY-MM-DD')
        localStorage.removeItem('voucherFormdata')
      }
    },
    chooseBizDate(data) {
      this.formData.bizDate = data.data
    },
    chooseAccountingDate(data) {
      this.formData.accountingDate = data.data
    },
    copyOrder() {
      let data = JSON.stringify(this.formData)
      localStorage.setItem('voucherFormdata', data)
      this.$router.push({ name: 'voucherDetail' })
    },
    backForm(back = 0) {
      goBack('voucherList', back)
    },
    addLineHandle() {
      const data = {
        summary: undefined,
        accountCode: undefined,
        accountName: undefined,
        currency: this.formData.functionalCurrency,
      }
      let summaryIndex = this.formData.detailList.findIndex((item) => item.type === 'summary')
      if (summaryIndex > -1) {
        this.formData.detailList.splice(-1, 0, data)
      } else {
        this.formData.detailList.push(data)
      }
    },
    deleteLineHandle() {
      if (this.selectedRowKeys.length === 0) {
        this.$message.warning(this.$t(`至少选中一项`))
        return
      }
      this.formData.detailList = this.formData.detailList.filter(
        (item, index) => !this.selectedRowKeys.includes(index)
      )
      this.selectedRowKeys = []
      this.$nextTick(() => {
        this.calTotalLine()
      })
    },
    showDimensionModal(record) {
      if (this.isDisabled) return
      this.dimensionVisible = true
      this.dimensionData = record
    },
    handleDimensionOk() {
      this.getShowName(this.dimensionData)
    },
    getShowName(data) {
      let dimensionListName = data.dimensionList
        .map((item) => {
          return `${item.accountingDimensionName}: ${item.accountingDimensionValueName}`
        })
        .join(';')
      this.$set(data, 'dimensionListName', dimensionListName)
    },
    blurHandle(val, record, name) {
      if (val) {
        this.$set(record, name, '')
      }
      this.$nextTick(() => {
        this.calTotalLine()
      })
    },
    onSubmit: debounce(async function (status) {
      const data = deepClone(this.formData)
      if (this.id) {
        data.id = this.id
      }
      data.status = status
      if (status === 'DRAFT') {
        this.draftLoading = true
      } else {
        this.saveLoading = true
      }
      data.detailList = data.detailList.filter((item) => item.type !== 'summary')
      data.detailList.forEach((item) => {
        if (item.creditAmount) {
          item.amount = item.creditAmount
          item.direction = 'CREDIT'
        } else {
          item.amount = item.debitAmount
          item.direction = 'DEBIT'
        }
      })
      await http({
        url: api.crateOrUpdateVoucher,
        data,
        success: (res) => {
          if (res.success) {
            this.$message.success({
              title: this.$t(`成功`),
              content: this.$t(`保存成功`),
            })
            if (this.isDetail) {
              this.init()
            } else {
              this.backForm()
            }
          }
        },
      })
      this.draftLoading = false
      this.saveLoading = false
    }),
    viewSourceDetail(record, index) {
      let sourceDocSnList = record.sourceDocSn.split(','),
        sourceDocIdList = record.sourceDocId.split(',')
      jumpSoucePage(sourceDocSnList[index], sourceDocIdList[index])
    },
    goToAccountJournalList(record) {
      const data = {
        accountCode: record.accountCode,
        accountName: record.accountName,
        accountSetCode: this.formData.accountSetCode,
        businessUnitCode: this.formData.businessUnitCode,
      }
      localStorage.setItem('accountJournalInfo', JSON.stringify(data))
      this.$router.push({
        name: 'accountJournalList',
      })
    },
    goToDimensionList(record) {
      const data = {
        accountSetCode: this.formData.accountSetCode,
        businessUnitCode: this.formData.businessUnitCode,
        sourceTable: record.accountingDimensionSourceTable,
        accountingDimensionCode: record.accountingDimensionCode,
        accountingDimensionName: record.accountingDimensionName,
        accountingDimensionValueCode: record.accountingDimensionValueCode,
        accountingDimensionValueName: record.accountingDimensionValueName,
      }
      localStorage.setItem('accountJournalDimensionInfo', JSON.stringify(data))
      this.$router.push({
        name: 'accountJournalDimensionList',
      })
    },
    calTotalLine() {
      this.formData.detailList = this.formData.detailList.filter((item) => item.type !== 'summary')
      if (this.formData.detailList.length === 1) return
      let debitTotalAmount = 0,
        creditTotalAmount = 0
      this.formData.detailList.forEach((item) => {
        debitTotalAmount = add(debitTotalAmount, item.debitAmount || 0)
        creditTotalAmount = add(creditTotalAmount, item.creditAmount || 0)
      })
      this.formData.detailList.push({
        type: 'summary',
        debitAmount: debitTotalAmount,
        creditAmount: creditTotalAmount,
      })
    },
    showAccountModal(index) {
      this.modalIndex = index
      this.$refs.SelectAccountModalRef.open()
    },
    selectAccountHandle(list) {
      for (let i = 0; i < list.length; i++) {
        const data = this.getAccountDimension(list[i])
        this.formData.detailList.splice(this.modalIndex + i, i === 0 ? 1 : 0, data)
      }
    },
    getAccountDimension({ id, code, name, dimension_list }) {
      if (!id) return
      const data = {
        summary: undefined,
        currency: this.formData.currency,
        dimensionList: [],
        accountCode: code,
        accountName: name,
      }
      if (dimension_list.length > 0) {
        let list = dimension_list.map((item) => ({
          accountingDimensionSourceTable: item.source_table,
          accountingDimensionCode: item.value_code,
          accountingDimensionName: item.value_name,
          accountingDimensionValueCode: '',
          accountingDimensionValueName: '',
        }))
        data.dimensionList = list
        this.getShowName(data)
      }
      return data
    },
  },
}
</script>
